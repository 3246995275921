<template>
    <div>
        <div v-if="$vuetify.breakpoint.mdAndDown">
            <v-layout row wrap align-center justify-center fill height>
                <v-flex xs12>
                    <span class="subtitle-1">
                        <v-icon left>{{ icon }}</v-icon> 
                        {{ title }}
                    </span>   
                </v-flex>
                <v-flex xs12>
                    <v-btn dark color="success" @click="showDialog()" block outlined>
                        <v-icon small left>mdi-forward</v-icon>
                        Pošlji
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>
        <div v-else>
            <v-list>
                <v-list-item
                @click="showDialog()"
                >
                    <v-list-item-icon v-if="icon != '' && icon != null">
                        <v-icon color="">{{ icon }}</v-icon>
                    </v-list-item-icon>
            
                    <v-list-item-content >
                        <v-list-item-title class="body-2" v-text="title"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn dark color="success" small @click="showDialog()">
                            <v-icon small left>mdi-forward</v-icon>
                            Pošlji
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </div>

        <v-dialog v-model="dialog" scrollable max-width="760px">
            <v-card>
                <v-card-title id="dialog_card_title">
                    Ste prepričani da želite uporabniku dovolilnice poslati obvestilo o stanju dovolilnice številka #{{ permit.id }}?
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <br>
                    <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                    Spoštovani <strong>{{ permit.user.name }}</strong>,<br>
                    Vaša vloga za izdajo dovolilnice je bila uspešno obdelana.
                    <br><br>
                    Številka vaše dovolilnice: <strong>{{ permit.id }}</strong>
                    <br>
                    Vaša telefonska številka za uporabo potopnih stebričkov je: <strong>{{ permit.phone_number }}</strong>
                    <br>
                    Tip dovolilnice: <strong>{{ permit.permit_type.name }}</strong>
                    <br>
                    Vaša dovolilnica je veljavna med datumi: <strong>{{ period }}</strong>

                    <br><br>
                    <p><strong>NAVODILA ZA UPORABO POTOPNIH STEBRIČKOV</strong></p>
                    <p>Ob vstopu in izstopu je potrebno poklicati stebriček, in sicer tako, da vozilo obvezno stoji na zanki – tik pred stebričkom. Počakati je potrebno na zeleno luč na semaforju, nato lahko vozilo spelje. Potopni stebriček se bo po prevozu zanke nazaj avtomatsko zaprl.</p>
                    <br>
                    Telefonska številka stebrička:<br>
                    POD JELOVICO: 041 386 824
                    <br><br>
                    Za izhod ni potrebno klicati stebrička, odpira se avtomatsko.
                    V kolikor bodo težave se prosim takoj obrnite na nas.
                    <br><br>
                    Za ostala dva potopna stebrička pa je treba poklicati v obeh smereh:
                    <br><br>
                    Potopni stebriček fijakerji: 041397149<br>
                    Potopni stebriček casino: 051305221
                    <br>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="sendEmail()"><v-icon left>mdi-arrow-right</v-icon>Pošlji</v-btn>
                    <v-btn :disabled="disableSubmit" color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ closeWindow }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {
    name: 'promenada-permit-status-notification',
    props: ['permit'],
    components: {
        FormSubmitMessages
    },

    data: () => ({
        title: "Obvesti uporabnika o stanju dovolilnice",
        icon: 'mdi-check',

        dialog: false,
        disableSubmit: false,
        loading: false,

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        closeWindow: "Prekliči"

    }),

    computed: {
        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        period() {
            if(this.permit != null) {
                return this.permit.restriction_start_date_formatted + " - " + this.permit.restriction_end_date_formatted
            }

            return ""
        }
    },

    methods: {
        sendEmail() {
            this.clearMessages()
            this.loading = true
            this.disableSubmit = true

            this.$store.dispatch('PROMENADA_PERMIT_STATUS_NOTIFICATION_EMAIL', {
                permit_id: this.permit.id,
                user_name: this.permit.user.name,
                user_email: this.permit.user.email,
                phone_number: this.permit.phone_number,
                user_id: this.permit.user.id,
                permit_type: this.permit.permit_type.name,
                restriction_start_date_formatted: this.permit.restriction_start_date_formatted,
                restriction_end_date_formatted: this.permit.restriction_end_date_formatted

            })
            .then(response => {
                //window.console.log(response)

                this.backendMessages.push("Pošiljanje e-mail sporočila je uspelo.");
                this.closeWindow = 'Zapri'

            })
            .catch(error => {
                window.console.error("RejectApplicationNotification@sendEmail");
                window.console.log(error)
                window.console.log(error.response)

                this.backendErrorMessages.push('Pri pošiljanju elektronskega sporočila je prišlo do napake.');
            })
            .finally(() => {
                this.loading = false
                this.disableSubmit = false
            })


        },

        showDialog() {
            this.dialog = true
        },

        cancel() {
            this.clearForm();
            this.dialog = false
        },

        clearForm() {
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        clearMessages() {
            this.backendMessages = []
            this.backendErrorMessages = []
        }
    },

    created() {

    },

    mounted() {
        //window.console.log("Permit ...")
        //window.console.log(this.permit)
    },

    destroyed() {

    }
}

</script>

<style scoped>
    #dialog_card_title {
        font-size: 16px !important;
    }
</style>